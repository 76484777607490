<template>
  <el-dialog
    :visible.sync="visible"
    width="60%"
    :before-close="handleClose"
  >
    <pdf ref="pdf" :src="pdfUrl" :page="pageNum" class="pdf" @num-pages="pageCount=$event" />
    <div class="pdf-footer">
      <div class="info">
        <div>当前页数/总页数：{{ pageNum }}/{{ pageCount }}</div>
      </div>
      <div class="footer">
        <div @click="prePage">
          上一页
        </div>
        <div @click="nextPage">
          下一页
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" plain class="widen-button" @click="handleClose">取 消</el-button>
      <el-button size="small" type="primary" class="widen-button" @click="downloadFile(pdfUrl)">下 载</el-button>
      <el-button v-show="signOrNot" size="small" type="primary" class="widen-button" @click="sign">去签署</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { downloadFile } from '@/utils/util'
import pdf from 'vue-pdf'
export default {
  components: {
    pdf
  },
  props: {
    // 控制显隐
    visible: {
      type: Boolean,
      default: false
    },
    // 路径
    url: {
      type: String,
      default: ''
    },
    // 是否显示签署
    signOrNot: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      pdfUrl: '',
      pageNum: 1,
      pageCount: 0
    }
  },
  watch: {
    url: {
      handler(data) {
        // 图片路径
        this.pdfUrl = this.$fileUrl + data
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    downloadFile,
    // 签署
    sign() {
      this.$emit('confirmSign')
    },
    handleClose() {
      this.$emit('update:visible', false)
    },
    prePage() {
      if (this.pageNum === 1) {
        return
      }
      this.pageNum--
    },
    nextPage() {
      if (this.pageNum === this.pageCount) {
        return
      }
      this.pageNum++
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;
  div {
    padding: 10px;
    border: 1px solid #ddd;
    cursor: pointer;
  }
}
.pdf {
  margin: 0 auto;
  width: 80%;
}
</style>
